import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import {Session} from 'bc-react-session';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Auth/Login'));
const Page404 = React.lazy(() => import('./views/Error/Page404'));
const Page500 = React.lazy(() => import('./views/Error/Page500'));

const session = Session.get();
const authToken = (session.payload.user) ? session.payload.user.token : null;

class App extends Component {
  // constructor(props) {
  //   super(props);
  //     this.state = {
  //   };
  // }

  omponentDidMount() {
    // const { history } = this.props;
    // const authToken = (session.payload.user) ? session.payload.user.token : null;
  //  if(!authToken){
   //   history.push('/login');
  //  }else{
   //   this.setState({authToken: session.payload.user.token, currentUsername: session.payload.user.user.first_name+' '+session.payload.user.user.last_name});
      
    //}
  }

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
